import Component from "gia/Component";
import eventbus from "gia/eventbus";
import config from "gia/config";

import Muuri from "muuri";

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

class Grid extends Component {
  ///////////////////////////// CONSTRUCTOR //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  constructor(element) {
    super(element);
    //////
    this.ref = {
      content: null,
      items: [],
    };
    //////
    this.options = {
      name_block: "Grid",
    };

    ////////////////////////
    // Eventbus listeners //
    ////////////////////////

    this.el__g__updt__namespace = this.eh__g__updt__namespace.bind(this);
  }

  //////////////////////////////// MOUNT /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  mount() {
    config.set("log", false);

    this.init();

    ////////////////////////////////
    // Config. eventbus responses //
    ////////////////////////////////

    eventbus.on("global__updt__namespace", this.el__g__updt__namespace);
  }

  /////////////////////////////// UNMOUNT ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  unmount() {
    ///////////////////////////////////
    // Unregister eventbus listeners //
    ///////////////////////////////////

    eventbus.off("global__updt__namespace", this.el__g__updt__namespace);
  }

  //////////////////////////// STATE CHANGES /////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // stateChange(changes) {
  //   const state = this.state;
  //   const options = this.options;

  //   const name_block = options.name_block;

  //   ////////////////////////////////////
  //   ////////////////////////////////////
  // }

  //////////////////////////// EVENT HANDLERS ////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  eh__g__updt__namespace(args) {
    this.setState({
      namespace: args.namespace,
    });
  }

  ///////////////////////////// COMP. INIT. //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init() {
    ////////////////////
    // Muuri instance //
    ////////////////////

    this.init__Muuri();

    //////////////////
    // Interactions //
    //////////////////

    this.init__items();

    /////////////////////////////////////////
    // Mobile- & desktop-specific behavior //
    /////////////////////////////////////////

    const is__mobile = window.innerWidth < 768;

    if (is__mobile) {
      this.init__mobile();
    } else {
      this.init__desktop();
    }
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init__mobile() {}

  init__desktop() {}

  ////////////////////////////////////
  ////////////////////////////////////

  init__Muuri() {
    const content = this.ref.content;
    const instance_grid = new Muuri(content);
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init__items() {
    const items = this.ref.items;
    for (let item of items) {
      item.addEventListener("mouseenter", () => {
        const type = item.dataset.type;
        const args = {
          ids:
            type === "place"
              ? JSON.parse(item.dataset.artists)
              : [item.dataset.id],
        };
        eventbus.emit("gridItem__mouseEnter", args);
      });

      item.addEventListener("mouseleave", () => {
        eventbus.emit("gridItem__mouseLeave");
      });
    }
  }
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

export { Grid };
