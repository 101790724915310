import { app__config, app__config__namespace } from "./app__config";

app__config(true);

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

import Swup from "swup";
import SwupGiaPlugin from "@swup/gia-plugin";
import SwupPreloadPlugin from "@swup/preload-plugin";
import SwupBodyPlugin from "@swup/body-class-plugin";
import SwupFadeTheme from "@swup/fade-theme";
import loadComponents from "gia/loadComponents";
import eventbus from "gia/eventbus";

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

///////////////
// App comp. //
///////////////

import { Grid } from "./components/grid/grid";
import { Collapsible } from "./components/collapsible/Collapsible";
import { Interview } from "./components/interview/Interview";
import { Slider } from "./components/slider/Slider";
import { Map } from "./components/Map/Map";

const appComponents = { Grid, Collapsible, Interview, Slider, Map };

//////////////////
// Global comp. //
//////////////////

import { nav } from "./components/nav/nav";
import { ArtistList } from "./components/artistList/ArtistList";

loadComponents({
  nav,
  ArtistList,
});

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

///////////////////
// Window events //
///////////////////

window.addEventListener("load", () => {
  eventbus.emit("window__loaded");
});

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//////////
// Swup //
//////////

const swup = new Swup({
  plugins: [
    new SwupGiaPlugin({ components: appComponents, log: true }),
    new SwupPreloadPlugin(),
    new SwupFadeTheme(),
    new SwupBodyPlugin(),
  ],
});

/////////////////
// Swup events //
/////////////////

swup.on("clickLink", (e) => {
  const clickedLink = e.delegateTarget;
  const type = clickedLink.dataset.type;
  //////
  let args = { clickedLink: clickedLink };
  eventbus.emit("swup__clickLink", args);
  //////
  app__config__namespace(false, clickedLink.dataset.namespace);

  ///////////////////////////
  // Update artist-list... //
  ///////////////////////////

  if (type === "place") {
    eventbus.emit("artistList__update", args);
  } else {
    eventbus.emit("artistList__clear");
  }

  ///////////////////////////
  // Update back-button... //
  ///////////////////////////

  // if (clickedLink.hasAttribute("data-parent")) {
  //   const parent = clickedLink.dataset.parent;
  //   //////
  //   args = { url: parent };
  //   eventbus.emit("nav__updt_backButton", args);
  // }
});

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
