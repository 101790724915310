import Component from "gia/Component";
import eventbus from "gia/eventbus";
import config from "gia/config";

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

class nav extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = {
      links: [],
      backButton: null,
      fullscreenButton: null,
    };
    //////
    this.options = {
      name_block: "nav",
    };

    ////////////////////////
    // Eventbus listeners //
    ////////////////////////

    this.el__g__updt__namespace = this.eh__g__updt__namespace.bind(this);
    this.el__updt__backbutton = this.eh__updt__backbutton.bind(this);
  }

  //////////////////////////////// MOUNT /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  mount() {
    config.set("log", false);

    this.init();

    ////////////////////////////////
    // Config. eventbus responses //
    ////////////////////////////////

    eventbus.on("global__updt__namespace", this.el__g__updt__namespace);
  }

  //////////////////////////// STATE CHANGES /////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(changes) {
    const state = this.state;
    const options = this.options;
    const refs = this.ref;

    const name_block = options.name_block;

    ////////////////////////////////////
    ////////////////////////////////////

    if ("link_active" in changes) {
      const link_active = state.link_active;
      const links = refs.links;
      //////
      links.forEach((link) => {
        if (link.dataset.id === link_active) {
          link.classList.add(name_block + "__link_active");
        } else {
          link.classList.remove(name_block + "__link_active");
        }
      });
    }

    ////////////////////////////////////
    ////////////////////////////////////

    if ("backButton" in changes) {
      const backButton = refs.backButton;
      backButton.href = state.backButton.url;
    }

    ////////////////////////////////////
    ////////////////////////////////////

    if ("fullscreen" in changes) {
      if (state.initialised) {
        if (state.fullscreen) {
          if (document.body.requestFullscreen) {
            document.body.requestFullscreen();
          } else if (document.body.webkitRequestFullscreen) {
            document.body.webkitRequestFullscreen();
          }
        } else {
          document.exitFullscreen();
        }
      }
    }
  }

  //////////////////////////// EVENT HANDLERS ////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  eh__g__updt__namespace(args) {
    this.setState({
      namespace: args.namespace,
    });
  }

  eh__updt__backbutton(args) {
    this.setState({
      backButton: {
        url: args.url,
      },
    });
  }

  ///////////////////////////// COMP. INIT. //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init() {
    //////////////////
    // Init. states //
    //////////////////

    this.setState({
      initialised: false,
      fullscreen: false,
    });

    //////////////////
    // Interactions //
    //////////////////

    this.init__links();
    this.init__backButton();
    this.init__fullscreenButton();

    /////////////////////////////////////////
    // Mobile- & desktop-specific behavior //
    /////////////////////////////////////////

    const is__mobile = window.innerWidth < 768;

    if (is__mobile) {
      this.init__mobile();
    } else {
      this.init__desktop();
    }

    /////////////////
    // Init. comp. //
    /////////////////

    this.setState({
      initialised: true,
    });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init__mobile() {}

  init__desktop() {}

  ////////////////////////////////////
  ////////////////////////////////////

  init__links() {
    const refs = this.ref;
    const links = refs.links;
    //////
    for (let link of links) {
      link.addEventListener("click", () => {
        const id = link.dataset.id;
        this.setState({
          link_active: id,
        });
      });
    }
  }

  init__backButton() {
    eventbus.on("nav__updt_backButton", this.el__updt__backbutton);
  }
  init__fullscreenButton() {
    const fullscreenButton = this.ref.fullscreenButton;
    if (fullscreenButton) {
      fullscreenButton.addEventListener("click", () => {
        this.setState({
          fullscreen: true,
        });
      });
    }
  }
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

export { nav };
