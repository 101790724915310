import Component from "gia/Component";
import eventbus from "gia/eventbus";
import config from "gia/config";

import { Loader, LoaderOptions } from "google-maps";

import styles from "./Map.styles";

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

class Map extends Component {
  ///////////////////////////// CONSTRUCTOR //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  constructor(element) {
    super(element);
    //////
    this.ref = {
      content: null,
    };
    //////
    this.options = {
      name_block: "map",
    };

    ////////////////////////
    // Eventbus listeners //
    ////////////////////////
  }

  //////////////////////////////// MOUNT /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  mount() {
    config.set("log", false);
    this.init();
  }

  //////////////////////////// STATE CHANGES /////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(changes) {
    const refs = this.ref;
    const state = this.state;
    const options = this.options;

    const name_block = options.name_block;

    ////////////////////////////////////
    ////////////////////////////////////
  }

  //////////////////////////// EVENT HANDLERS ////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  ///////////////////////////// COMP. INIT. //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init() {
    this.init_GoogleMap();
  }

  init_GoogleMap() {
    this.init_GoogleMap__loader();
  }

  init_GoogleMap__loader() {
    const refs = this.ref;
    const content = refs.content;
    //////
    const loader = new Loader("AIzaSyAQzWa0mVxOSqsYqPv_2-KyeGLeaaJRbrw");
    loader.load().then(function (google) {
      const map = new google.maps.Map(content, {
        center: { lat: 42.233500466824374, lng: 10.248342866811543 },
        zoom: 4,
        styles: styles,
        disableDefaultUI: true,
      });

      ///////////////////
      // Add marker... //
      ///////////////////

      const icon = {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 9,
        fillColor: "#FFF",
        fillOpacity: 1,
        strokeColor: "#fff",
        strokeWeight: 0,
      };
      const locations = [
        {
          title: "Dresden",
          position: { lat: 51.05946924410807, lng: 13.741377136452531 },
          url: "/places/dresden-germany",
        },
        {
          title: "Istanbul",
          position: { lat: 41.03011623553442, lng: 28.973349584517603 },
          url: "/places/istanbul",
        },
        {
          title: "Kuwait",
          position: { lat: 29.343732766405445, lng: 48.04098668429437 },
          url: "/places/kuwait-city-the-state-of-kuwait",
        },
        {
          title: "Be‘er Sheva",
          position: { lat: 31.237835563334915, lng: 34.78995831132008 },
          url: "/places/be-er-sheva-israel",
        },
        {
          title: "Limassol",
          position: { lat: 34.676342283168594, lng: 33.041671470930645 },
          url: "/places/limassol-cyprus",
        },
        {
          title: "Thessaloniki",
          position: { lat: 40.632536095712275, lng: 22.93556588458607 },
          url: "/places/thessaloniki",
        },
      ];

      for (let location of locations) {
        const marker = new google.maps.Marker({
          position: location.position,
          map,
          title: location.title,
          icon: icon,
        });
        marker.addListener("click", () => {
          window.location.href = location.url;
        });
      }
    });
  }

  /////////////////////////// COMP. FUNCTIONS ////////////////////////////
  ////////////////////////////////////////////////////////////////////////
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

export { Map };
