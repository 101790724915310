import eventbus from "gia/eventbus";
import { getRandomInt } from "./utilities/get_randomInt";

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

function app__config(logOnComplete) {
  /////////////////////////////////////////////////
  // Set global logging of ref.-element messages //
  /////////////////////////////////////////////////

  let config__log__refs = true;
  localStorage.setItem("app__log__refs", config__log__refs);

  /////////////////////////////////////////
  // Set global logging of AJAX messages //
  /////////////////////////////////////////

  let config__log__AJAX = true;
  localStorage.setItem("app__log__AJAX", config__log__AJAX);

  /////////////////////////
  // Set init. namespace //
  /////////////////////////

  eventbus.on("window__loaded", () => {
    app__config__namespace(true);
  });

  ///////////////////////
  // Log final config. //
  ///////////////////////

  if (logOnComplete) {
    const namespace = document.getElementById("swup").dataset.namespace;
    //////
    console.log(`
      App configured...

      ////////////////////////////////////////////////////////////////////////
      =>  log messages of comp. references: ${config__log__refs}
      =>  log AJAX-related messages       : ${config__log__AJAX}
      ////////////////////////////////////////////////////////////////////////
      =>  init. namespace                 : ${namespace}
      ////////////////////////////////////////////////////////////////////////
      =>  CMS                             : Kirby
      =>  CMS Version                     : ${app__config__get__cmsVersion()}

      ////////////////////////////////////////////////////////////////////////
      ////////////////////////////////////////////////////////////////////////

      Developed @ schmoll.studio, Spring 2022

      ////////////////////////////////////////////////////////////////////////
      ////////////////////////////////////////////////////////////////////////

      Grindset Rule #${getRandomInt(1, 999)}: Get smart.
    `);
  }
}

////////////////////////////////////
////////////////////////////////////

function app__config__namespace(init, name) {
  let namespace = "";
  if (init) {
    namespace = document.getElementById("swup").dataset.namespace;
  } else {
    namespace = name;
  }
  //////
  const args = {
    namespace: namespace,
  };
  localStorage.setItem("namespace", namespace);
  eventbus.emit("global__updt__namespace", args);
}

////////////////////////////////////
////////////////////////////////////

function app__config__get__cmsVersion() {
  return document.body.dataset.cmsVersion;
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

export { app__config, app__config__namespace };
