import Component from "gia/Component";
import eventbus from "gia/eventbus";
import config from "gia/config";

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

class Collapsible extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = { button: null, content: null };
    //////
    this.options = {
      name_block: "collapsible",
    };

    ////////////////////////
    // Eventbus listeners //
    ////////////////////////

    this.el__g__updt__namespace = this.eh__g__updt__namespace.bind(this);
  }

  //////////////////////////////// MOUNT /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  mount() {
    config.set("log", false);
    this.init();

    ////////////////////////////////
    // Config. eventbus responses //
    ////////////////////////////////

    eventbus.on("global__updt__namespace", this.el__g__updt__namespace);
  }

  /////////////////////////////// UNMOUNT ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  unmount() {
    ///////////////////////////////////
    // Unregister eventbus listeners //
    ///////////////////////////////////

    eventbus.off("global__updt__namespace", this.el__g__updt__namespace);
  }

  //////////////////////////// STATE CHANGES /////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(changes) {
    const state = this.state;
    const options = this.options;
    const refs = this.ref;

    const name_block = options.name_block;

    ////////////////////////////////////
    ////////////////////////////////////

    if ("hidden" in changes) {
      const content = refs.content;
      const button = refs.button;
      //////
      if (state.hidden) {
        content.classList.add(name_block + "__content_hidden");
        button.classList.remove(name_block + "__button_active");
      } else {
        content.classList.remove(name_block + "__content_hidden");
        button.classList.add(name_block + "__button_active");
      }
    }
  }

  //////////////////////////// EVENT HANDLERS ////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  eh__g__updt__namespace(args) {
    this.setState({
      namespace: args.namespace,
    });
  }

  ///////////////////////////// COMP. INIT. //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init() {
    //////////////////
    // Init. states //
    //////////////////

    this.setState({
      hidden: true,
    });

    //////////////////
    // Interactions //
    //////////////////

    this.init__button();

    /////////////////////////////////////////
    // Mobile- & desktop-specific behavior //
    /////////////////////////////////////////

    const is__mobile = window.innerWidth < 768;

    if (is__mobile) {
      this.init__mobile();
    } else {
      this.init__desktop();
    }
  }

  init__mobile() {}

  init__desktop() {}

  ////////////////////////////////////
  ////////////////////////////////////

  init__button() {
    const button = this.ref.button;
    button.addEventListener("click", () => {
      this.setState({
        hidden: !this.state.hidden,
      });
    });
  }
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

export { Collapsible };
