import Component from "gia/Component";
import eventbus from "gia/eventbus";
import config from "gia/config";

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

class ArtistList extends Component {
  ///////////////////////////// CONSTRUCTOR //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  constructor(element) {
    super(element);
    //////
    this.ref = {
      links: [],
      linkGroup_exhibition: null,
      linkGroup_exhibition__content: null,
      linkGroup_core: null,
      linkGroup_all: null,
    };
    //////
    this.options = {
      name_block: "artistList",
      showOnInit_linkGroup_exhibition: "defaultValue",
    };

    ////////////////////////
    // Eventbus listeners //
    ////////////////////////

    this.el__g__updt__namespace = this.eh__g__updt__namespace.bind(this);
    this.el__gridItem__mouseEnter = this.eh__gridItem__mouseEnter.bind(this);
    this.el__gridItem__mouseLeave = this.eh__gridItem__mouseLeave.bind(this);
    this.el__swup__clickLink = this.eh__swup__clickLink.bind(this);
    this.el__artistList__update = this.eh__artistList__update.bind(this);
    this.el__artistList__clear = this.eh__artistList__clear.bind(this);
  }

  //////////////////////////////// MOUNT /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  mount() {
    config.set("log", false);
    this.init();
  }

  //////////////////////////// STATE CHANGES /////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(changes) {
    const refs = this.ref;
    const state = this.state;
    const options = this.options;

    const name_block = options.name_block;

    ////////////////////////////////////
    ////////////////////////////////////

    if ("hidden" in changes) {
      const hidden = state.hidden;
      //////
      if (hidden) {
        this.element.classList.add(name_block + "_hidden");
      } else {
        this.element.classList.remove(name_block + "_hidden");
      }
    }

    ////////////////////////////////////
    ////////////////////////////////////

    if ("links_active" in changes) {
      const links = this.element.querySelectorAll(".artistList__link");
      const links_active = state.links_active;
      //////
      if (links_active.length === 1 && links_active[0] === "null") {
        for (let link of links) {
          link.classList.remove(name_block + "__link_inactive");
        }
      } else {
        for (let link of links) {
          const id = link.dataset.id;
          if (!links_active.includes(id)) {
            link.classList.add(name_block + "__link_inactive");
          } else {
            link.classList.remove(name_block + "__link_inactive");
          }
        }
      }
    }

    ////////////////////////////////////
    ////////////////////////////////////

    if ("linkGroup_exhibition_hidden" in changes) {
      const linkGroup_exhibition = refs.linkGroup_exhibition;
      const linkGroup_exhibition_hidden = state.linkGroup_exhibition_hidden;
      //////
      if (linkGroup_exhibition_hidden) {
        linkGroup_exhibition.classList.add(name_block + "__linkGroup_hidden");
      } else {
        linkGroup_exhibition.classList.remove(
          name_block + "__linkGroup_hidden"
        );
      }
    }

    if ("linkGroup_core_hidden" in changes) {
      const linkGroup_core = refs.linkGroup_core;
      const linkGroup_core_hidden = state.linkGroup_core_hidden;
      //////
      if (linkGroup_core_hidden) {
        linkGroup_core.classList.add(name_block + "__linkGroup_hidden");
      } else {
        linkGroup_core.classList.remove(name_block + "__linkGroup_hidden");
      }
    }
  }

  //////////////////////////// EVENT HANDLERS ////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  eh__g__updt__namespace(args) {
    this.setState({
      namespace: args.namespace,
    });
  }

  eh__gridItem__mouseEnter(args) {
    this.setState({
      links_active: args.ids,
    });
  }
  eh__gridItem__mouseLeave() {
    this.setState({
      links_active: ["null"],
    });
  }
  eh__swup__clickLink() {
    this.setState({
      links_active: ["null"],
    });
  }

  eh__artistList__update(args) {
    this.update(JSON.parse(args.clickedLink.dataset.artists));
  }
  eh__artistList__clear() {
    console.log("clearing exhibition artist list...");
    this.clear();
  }

  ///////////////////////////// COMP. INIT. //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init() {
    const options = this.options;

    //////////////////
    // Init. states //
    //////////////////

    this.setState({
      linkGroup_exhibition_hidden:
        options.showOnInit_linkGroup_exhibition != "1",
      linkGroup_core_hidden: options.showOnInit_linkGroup_exhibition === "1",
      exhibition_active: "null",
    });

    //////////////////
    // Interactions //
    //////////////////

    this.init__links();

    ////////////////////////
    // Eventbus Listeners //
    ////////////////////////

    this.init__eventbusListeners();

    /////////////////////////////////////////
    // Mobile- & desktop-specific behavior //
    /////////////////////////////////////////

    const is__mobile = window.innerWidth < 768;

    if (is__mobile) {
      this.init__mobile();
    } else {
      this.init__desktop();
    }
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init__mobile() {}

  init__desktop() {}

  ////////////////////////////////////
  ////////////////////////////////////

  init__links() {
    const links = this.ref.links;
    for (let link of links) {
      link.addEventListener("mouseenter", () => {
        const links_active_new = [link.dataset.id];
        //////
        this.setState({
          links_active: links_active_new,
        });
      });

      link.addEventListener("mouseleave", () => {
        this.setState({
          links_active: ["null"],
        });
      });
    }
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init__eventbusListeners() {
    eventbus.on("global__updt__namespace", this.el__g__updt__namespace);
    //////
    eventbus.on("gridItem__mouseEnter", this.el__gridItem__mouseEnter);
    eventbus.on("gridItem__mouseLeave", this.el__gridItem__mouseLeave);
    //////
    eventbus.on("swup__clickLink", this.el__swup__clickLink);
    //////
    eventbus.on("artistList__update", this.el__artistList__update);
    eventbus.on("artistList__clear", this.el__artistList__clear);
  }

  /////////////////////////// COMP. FUNCTIONS ////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  update(ARTISTS_ACTIVE) {
    this.setState({
      hidden: true,
    });
    //////
    setTimeout(() => {
      this.reorder(ARTISTS_ACTIVE);
      this.setState({
        linkGroup_exhibition_hidden: false,
        linkGroup_core_hidden: true,
        hidden: false,
      });
    }, 500);
  }

  reorder(ARTISTS_ACTIVE) {
    const refs = this.ref;
    const links = refs.links.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.dataset.id === value.dataset.id)
    );
    const linkGroup_exhibition__content = refs.linkGroup_exhibition__content;

    //////////////////////////////////////////////////
    // Clone + append link to »Exhibition Artists«, //
    // if related to clicked/entered place...       //
    //////////////////////////////////////////////////

    const links_new = [];

    for (let link of links) {
      const id = link.dataset.id;

      if (ARTISTS_ACTIVE.includes(id)) {
        const clone = link.cloneNode(true);
        //////
        clone.addEventListener("mouseenter", () => {
          const links_active_new = [clone.dataset.id];
          //////
          this.setState({
            links_active: links_active_new,
          });
        });
        clone.addEventListener("mouseleave", () => {
          this.setState({
            links_active: ["null"],
          });
        });
        //////
        links_new.push(clone);
      }
    }

    const links_new_sorted = this.sort_links(links_new);
    for (let link of links_new_sorted) {
      linkGroup_exhibition__content.append(link);
    }
  }

  clear() {
    const refs = this.ref;
    const linkGroup_exhibition__content = refs.linkGroup_exhibition__content;
    //////
    this.setState({
      hidden: true,
    });
    //////
    setTimeout(() => {
      while (linkGroup_exhibition__content.firstChild) {
        linkGroup_exhibition__content.removeChild(
          linkGroup_exhibition__content.lastChild
        );
      }
      //////
      this.setState({
        linkGroup_exhibition_hidden: true,
        linkGroup_core_hidden: false,
        hidden: false,
      });
    }, 500);
  }

  sort_links(LINKS) {
    const links_sorted = LINKS.sort(function (a, b) {
      const name_a =
        a.dataset.id.split("-")[a.dataset.id.split("-").length - 1];
      const name_b =
        b.dataset.id.split("-")[b.dataset.id.split("-").length - 1];
      //////
      if (name_a < name_b) {
        return -1;
      }
      if (name_a > name_b) {
        return 1;
      }
      return 0;
    });

    return links_sorted;
  }
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

export { ArtistList };
