import Component from "gia/Component";
import eventbus from "gia/eventbus";
import config from "gia/config";

import Swiper from "swiper";

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

class Slider extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = { container: null, counter: null, title: null };
    //////
    this.options = {
      name_block: "slider",
    };

    ////////////////////////
    // Eventbus listeners //
    ////////////////////////

    this.el__g__updt__namespace = this.eh__g__updt__namespace.bind(this);
    this.el__event = this.eh__event.bind(this);
  }

  //////////////////////////////// MOUNT /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  mount() {
    config.set("log", false);

    this.init();

    ////////////////////////////////
    // Config. eventbus responses //
    ////////////////////////////////

    eventbus.on("global__updt__namespace", this.el__g__updt__namespace);
    eventbus.on("someEvent", this.lst_evb__event);
  }

  /////////////////////////////// UNMOUNT ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  unmount() {
    ///////////////////////////////////
    // Unregister eventbus listeners //
    ///////////////////////////////////

    eventbus.off("global__updt__namespace", this.el__g__updt__namespace);
    eventbus.off("someEvent", this.lst_evb__event);
  }

  //////////////////////////// STATE CHANGES /////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(changes) {
    const state = this.state;
    const options = this.options;
    const refs = this.ref;

    const name_block = options.name_block;

    ////////////////////////////////////
    ////////////////////////////////////

    if ("title_active" in changes) {
      const title_active = state.title_active;
      const el_title = refs.title;
      el_title.innerHTML = title_active;
    }

    if ("count_active" in changes) {
      const count_active = state.count_active;
      const counter = refs.counter;
      counter.dataset.count = count_active;
    }
  }

  //////////////////////////// EVENT HANDLERS ////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  eh__g__updt__namespace(args) {
    this.setState({
      namespace: args.namespace,
    });
  }

  eh__event(args) {
    console.log("handling event...", args);
  }

  ///////////////////////////// COMP. INIT. //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init() {
    this.init__swiper();

    //////////////////
    // Init. states //
    //////////////////

    this.setState({
      hidden: true,
    });

    /////////////////////////////////////////
    // Mobile- & desktop-specific behavior //
    /////////////////////////////////////////

    const is__mobile = window.innerWidth < 768;

    if (is__mobile) {
      this.init__mobile();
    } else {
      this.init__desktop();
    }
  }

  init__mobile() {}

  init__desktop() {}

  ////////////////////////////////////
  ////////////////////////////////////

  init__swiper() {
    const instance_swiper = new Swiper(this.ref.container, {
      slidesPerView: "auto",
      spaceBetween: 10,
    });

    instance_swiper.on("slideChange", () => {
      const slide_curr = instance_swiper.slides[instance_swiper.activeIndex];
      //////
      this.setState({
        title_active: slide_curr.dataset.title,
        count_active: instance_swiper.activeIndex + 1,
      });
    });
  }
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

export { Slider };
